import * as React from 'react'
import cn from 'classnames'

export type RowProps = {
  children: React.ReactNode
  showBottomBorder?: boolean
} & React.ComponentProps<'tr'>

export const Row: React.VFC<RowProps> = ({
  children,
  showBottomBorder = true,
  className,
  ...props
}) => {
  return (
    <tr
      {...props}
      className={cn(
        '',
        className,
        showBottomBorder && 'border-0 border-b border-gray-100 border-solid',
        'h-px group/row',
        props.onClick && 'cursor-pointer transition-colors hover:bg-gray-50/50'
      )}
    >
      {children}
    </tr>
  )
}
