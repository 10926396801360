import { CircleDashed } from '@phosphor-icons/react'
import * as React from 'react'

export type NoResultsRowProps = {
  children?: React.ReactNode
  colspan?: number
}

export const NoResultsRow: React.VFC<NoResultsRowProps> = (props) => {
  const { children, colspan } = props

  return (
    <tr className="h-px">
      <td colSpan={colspan}>
        <div className="text-center text-gray-600 my-12 flex flex-col gap-2 items-center justify-center">
          {children ? null : <CircleDashed className="h-6 w-6 text-gray-200" />}
          {children || 'No results found'}
        </div>
      </td>
    </tr>
  )
}
