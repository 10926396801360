import * as React from 'react'
import cn from 'classnames'

export type CellProps = {
  children: React.ReactNode
  colspan?: number
  isLocked?: boolean
  pinned?: 'right' | 'left' | false
  className?: string
  showColumns?: boolean
}

export const Cell: React.VFC<CellProps> = (props) => {
  const { children, isLocked, colspan, pinned, className, showColumns } = props

  return (
    <td
      colSpan={colspan}
      className={cn(
        isLocked && 'opacity-50',
        'relative px-5 py-4 h-[inherit] flex-shrink-0',
        pinned && 'sticky bg-white z-[2]',
        pinned && 'sticky bg-white z-10',
        pinned === 'left' && 'left-0',
        pinned === 'right' && 'right-0',
        className
      )}
    >
      {showColumns && (
        <span
          className={cn(
            'absolute w-0 h-full border-0 border-r border-gray-50 border-solid top-0 right-0',
            pinned && 'border-gray-200',
            pinned === 'right' && 'left-0'
          )}
        />
      )}
      {children}
    </td>
  )
}
