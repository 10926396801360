import * as React from 'react'
import cn from 'classnames'

export type RootProps = {
  className?: string
  children: React.ReactNode
}

export const Root: React.VFC<RootProps> = (props) => {
  const { className, children } = props

  return (
    <table className={cn('min-w-full relative z-0', className)}>
      {children}
    </table>
  )
}
