import * as React from 'react'

export type BodyProps = {
  children: React.ReactNode
}

export const Body: React.VFC<BodyProps> = (props) => {
  const { children } = props

  return <tbody>{children}</tbody>
}
