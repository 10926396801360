import * as React from 'react'

export type HeadProps = {
  children: React.ReactNode
}

export const Head: React.VFC<HeadProps> = (props) => {
  const { children } = props

  return <thead>{children}</thead>
}
