import { CaretDown, ArrowDown, Info } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { Header as BaseHeader } from '@tanstack/react-table'
import { trackEvent } from '../../services/event-tracker'
import { Tooltip } from 'src/design-system'

export type HeaderProps<TData, TValue> = {
  children: React.ReactNode
  header: BaseHeader<TData, TValue>
  loading?: boolean
  pinned?: 'right' | 'left' | false
  showColumns?: boolean
  stickyHeader?: boolean
  tooltipContent?: string
  /**
   * The source of the table. Used for tracking events ($track_table_event).
   */
  source: string
}

export const Header = <TData, TValue>(props: HeaderProps<TData, TValue>) => {
  const {
    children,
    header,
    loading,
    source,
    pinned,
    showColumns,
    stickyHeader,
    tooltipContent,
  } = props

  const activeHeaderSort = header.column.getIsSorted() as string

  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    nextSortingOrder: string | boolean
  ) => {
    if (!loading) {
      header.column.getToggleSortingHandler()?.(e)

      trackEvent('$track_table_event', {
        source,
        action: 'sort',
        actionDetails: {
          column: header.column.id,
          direction: nextSortingOrder,
        },
      })
    }
  }

  return (
    <th
      key={header.id}
      className={cn(
        loading && 'cursor-wait',
        'relative py-3 pl-5 pr-3 font-bold group',
        pinned && 'sticky bg-white shadow-sm',
        pinned === 'left' && 'left-0',
        pinned === 'right' && 'right-0'
      )}
    >
      {header.isPlaceholder ? null : (
        <div className="flex flex-row justify-between gap-x-2 items-center whitespace-nowrap">
          <div className="flex items-center gap-1">
            {children}
            {tooltipContent && (
              <Tooltip content={tooltipContent} side="top">
                <div className="cursor-pointer select-none hover:bg-gray-50 rounded px-1">
                  <Info aria-hidden size={12} className="fill-gray-500" />
                </div>
              </Tooltip>
            )}
          </div>
          <div className="flex gap-1">
            {header.column.getCanSort() && (
              <button
                className={cn(
                  header.column.getCanSort() &&
                    !loading &&
                    'cursor-pointer select-none hover:bg-gray-50 rounded px-1.5'
                )}
                onClick={(e) => onClick(e, header.column.getNextSortingOrder())}
              >
                <CaretDown
                  aria-hidden
                  className={cn(
                    'w-3 h-3 flex-shrink-0 opacity-0 group-hover:opacity-50 group-focus:opacity-50',
                    activeHeaderSort && '!opacity-100',
                    activeHeaderSort === 'desc' && '-rotate-180'
                  )}
                  weight="bold"
                />
              </button>
            )}
          </div>
        </div>
      )}
      {showColumns && (
        <span
          className={cn(
            'absolute w-0 h-full border-0 border-r border-gray-50 border-solid top-0 right-0',
            pinned && 'border-gray-200',
            pinned === 'right' && 'left-0'
          )}
        />
      )}
      {stickyHeader && (
        <span className="absolute w-full h-0 border-0 border-b border-gray-100 border-solid bottom-0 left-0" />
      )}
    </th>
  )
}
